<template>
  <b-row v-if="$can('read', 'Analytics.Payment')">
    <b-col cols="12">
      <payment-analytics-filters @search="search" />
    </b-col>
    <b-col v-if="reportOptional.length > 0" cols="12">
      <b-card>
        <b-form-checkbox v-model="reportSelected" value="Daily" inline>
          Daily Report
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="Monthly" class="ml-2" inline>
          Monthly Report
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="MerchantTop" class="ml-2" inline>
          Merchant Top 10
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="AppTop" class="ml-2" inline>
          APP Top 10
        </b-form-checkbox>
      </b-card>
    </b-col>
    <b-col v-if="reportSelected.includes('Daily')" cols="12">
      <daily-report :data="daily" />
    </b-col>
    <b-col v-if="reportSelected.includes('Monthly')" cols="12">
      <monthly-report :data="monthly" />
    </b-col>
    <b-col v-if="reportSelected.includes('MerchantTop')" cols="12">
      <merchant-report :data="merchantTop" />
    </b-col>
    <b-col v-if="reportSelected.includes('AppTop')" cols="12">
      <app-report :data="appTop" />
    </b-col>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BCard, BOverlay,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'

import PaymentAnalyticsFilters from './PaymentAnalyticsFilters.vue'
import DailyReport from './DailyPaymentReport.vue'
import MonthlyReport from './MonthlyPaymentReport.vue'
import MerchantReport from './MerchantDoughnutReport.vue'
import AppReport from './AppDoughnutReport.vue'

import useDashboardJs from './dashboard'

const {
  fetchPaymentMerchantTop,
  fetchPaymentMerchantAppTop,
  fetchPaymentDaily,
  fetchPaymentMonthly,
} = useDashboardJs()

export default {
  components: {
    PaymentAnalyticsFilters,
    BRow,
    BCol,
    BFormCheckbox,
    BCard,
    BOverlay,
    DailyReport,
    MonthlyReport,
    MerchantReport,
    AppReport,
  },
  data() {
    return {
      loading: false,
      reportOptional: [],
      reportSelected: [],
      // 'Daily', 'Monthly', 'MerchantTop', 'AppTop'
      merchantTop: null,
      appTop: null,
      daily: null,
      monthly: null,
    }
  },
  watch: {
    reportSelected: function redraw(value) {
      const {
        daily, monthly, merchantTop, appTop,
      } = this

      if (value.includes('Daily')) {
        this.daily = null
      }
      if (value.includes('Monthly')) {
        this.monthly = null
      }
      if (value.includes('MerchantTop')) {
        this.merchantTop = null
      }
      if (value.includes('AppTop')) {
        this.appTop = null
      }
      setTimeout(() => {
        if (value.includes('Daily')) {
          this.daily = daily
        }
        if (value.includes('Monthly')) {
          this.monthly = monthly
        }
        if (value.includes('MerchantTop')) {
          this.merchantTop = merchantTop
        }
        if (value.includes('AppTop')) {
          this.appTop = appTop
        }
      }, 500)
    },
  },
  methods: {
    search(params) {
      this.reportOptional = []
      this.reportSelected = []
      this.daily = null
      this.monthly = null
      this.merchantTop = null
      this.appTop = null

      this.loading = true
      fetchPaymentDaily(
        params,
        data => {
          this.daily = data
          this.reportOptional.push('Daily')
          this.reportSelected.push('Daily')
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch daily data fail with ${fail}.`)
        },
      )

      fetchPaymentMonthly(
        params,
        data => {
          this.monthly = data
          this.reportOptional.push('Monthly')
          this.reportSelected.push('Monthly')
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch monthly data fail with ${fail}.`)
        },
      )

      fetchPaymentMerchantTop(
        params,
        data => {
          this.merchantTop = data
          this.reportOptional.push('MerchantTop')
          this.reportSelected.push('MerchantTop')
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch merchant analysis data fail with ${fail}.`)
        },
      )
      if (params.merchant_no !== '') {
        fetchPaymentMerchantAppTop(
          params,
          data => {
            this.appTop = data
            this.reportOptional.push('AppTop')
            this.reportSelected.push('AppTop')
          },
          fail => {
            showToast(this, 'Warning', `fetch app analysis data fail with ${fail}.`)
          },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
