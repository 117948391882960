<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Merchant</label>
          <v-select
            v-model="merchantFilter"
            :options="merchantOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>APP</label>
          <v-select
            v-model="appFilter"
            :options="appOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Method</label>
          <v-select
            key="type_name"
            v-model="methodFilter"
            label="type_desc"
            :options="methodOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.type_name"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD"
            range
            confirm
            :clearable="false"
            :disabled-date="dateDisabled"
            :shortcuts="shortcuts"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label />
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Query
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import useDashboardJs from './dashboard'

const {
  fetchMerchants,
  fetchMerchantApps,
  fetchChannelFilters,
} = useDashboardJs()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    vSelect,
    DatePicker,
  },
  props: {
  },
  data() {
    return {
      params: {
        merchant_no: '',
        app_id: '',
        method: '',
        trade_status: '',
        start_time: '',
        end_time: '',
        channel: '',
      },

      payChannelList: [],

      merchantFilter: '',
      merchantOptions: [{ label: 'All', value: '' }],
      appFilter: '',
      appOptions: [{ label: 'All', value: '' }],
      methodFilter: { type_id: '', type_name: '', type_desc: 'All' },
      methodOptions: [],

      dateModeFilter: '1',
      dateModeOptions: [
        { label: 'Create Time', value: '1' },
        { label: 'Update Time', value: '2' },
      ],

      dateRange: [],
      shortcuts: [{
        text: 'Last 7 Days',
        onClick() {
          const end = moment().endOf('day')
            .toDate()
          const start = moment().subtract(6, 'days').startOf('day')
            .toDate()
          return [start, end]
        },
      }, {
        text: 'This Month',
        onClick() {
          const end = moment().endOf('month').endOf('day')
            .toDate()
          const start = moment().startOf('month').startOf('day')
            .toDate()
          return [start, end]
        },
      }, {
        text: 'Last Month',
        onClick() {
          const end = moment().subtract(1, 'month').endOf('month').endOf('day')
            .toDate()
          const start = moment().subtract(1, 'month').startOf('month').startOf('day')
            .toDate()
          return [start, end]
        },
      }, {
        text: 'Last 3 Months',
        onClick() {
          const end = moment().subtract(1, 'month').endOf('month').endOf('day')
            .toDate()
          const start = moment().subtract(3, 'month').startOf('month').startOf('day')
            .toDate()
          return [start, end]
        },
      }],
    }
  },
  watch: {
    dateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment(startTime)
      const endTzTime = moment(endTime)
      const startTimeStr = startTzTime.clone().format('YYYY-MM-DD')
      const endTimeStr = endTzTime.clone().format('YYYY-MM-DD')

      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:dateRange', dateArray)
    },
    merchantFilter: function fillApps(merchantNo) {
      this.appOptions = []
      this.appFilter = ''
      if (merchantNo === '') {
        this.appOptions = [{ label: 'All', value: '' }]
        this.appFilter = ''
      } else {
        fetchMerchantApps(
          merchantNo,
          apps => {
            this.appOptions = apps
            this.appFilter = ''
          },
          fail => {
            showToast(this, 'Warning', `fetch APPs fail with ${fail}.`)
          },
        )
      }
    },
  },
  created() {
    fetchMerchants(
      merchants => {
        this.merchantOptions = merchants
      },
      fail => {
        showToast(this, 'Warning', `fetch Merchants fail with ${fail}.`)
      },
    )
    fetchChannelFilters(
      filters => {
        this.methodOptions = filters.methods
        this.methodFilter = ''
      },
      fail => {
        showToast(this, 'Warning', `fetch Methods fail with ${fail}.`)
      },
    )
    this.dateRange = [moment().subtract(31, 'day').startOf('days').format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD')]
  },
  methods: {
    dateDisabled(date) {
      return date.getTime() < new Date(2021, 0, 1, 0, 0, 0, 0).getTime()
    },
    genParams() {
      let [start, end] = this.dateRange

      start = moment(start).format('YYYY-MM-DD')
      end = moment(end).format('YYYY-MM-DD')

      const params = {
        merchant_no: this.merchantFilter,
        app_id: this.appFilter,
        method: this.methodFilter,
        start_time: start,
        end_time: end,
        date_mode: this.dateModeFilter,
      }

      return params
    },
    doSearch() {
      const params = this.genParams()
      this.$emit('search', params)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
