<template>
  <b-row>
    <b-col v-if="totalByCurrency.length > 0" cols="12">
      <b-card>
        <swiper
          class="swiper-centered-slides p-1"
          :options="swiperOptions"
        >

          <!-- slides -->
          <swiper-slide
            v-for="item in totalByCurrency"
            :key="item.currency"
            class="rounded swiper-shadow"
          >
            <b-media no-body @click="swiperSlideClick(item.currency)">
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  {{ item.currency }}
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-success">
                  {{ item.income }}
                </h4>
                <b-card-text class="font-small-3 mb-0 text-danger">
                  {{ item.outlay }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </swiper-slide>

          <!-- Add Arrows -->
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
        </swiper>
      </b-card>
    </b-col>

    <b-col v-if="totalByCurrency.length > 0" cols="12">
      <b-card>
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Daily Report
          </h4>
        </div>

        <!-- chart -->
        <e-charts
          ref="trendReportChart"
          class="chart"
          :option="trendReportChartOption"
        />
      </b-card>
    </b-col>

    <b-col v-if="totalByCurrency.length > 0" md="6">
      <b-card title="Methods By Amount">
        <e-charts
          ref="methodByAmountChart"
          class="chart"
          autoresize
        />
        <!-- <app-echart-doughnut ref="methodByAmountChart" :series="methodByAmountInfo" /> -->
      </b-card>
    </b-col>
    <b-col v-if="totalByCurrency.length > 0" md="6">
      <b-card title="Methods By Count">
        <e-charts
          ref="methodByCountChart"
          class="chart"
          autoresize
        />
        <!-- <app-echart-doughnut ref="methodByCountChart" :series="methodByCountInfo" /> -->
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BMedia, BMediaAside, BAvatar, BMediaBody, BRow, BCol,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
// import { LabelLayout } from 'echarts/features';
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import {
  GridComponent, TooltipComponent, ToolboxComponent, LegendComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  // LabelLayout,
  BarChart,
  LineChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
])

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BRow,
    BCol,

    ECharts,
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      categories: [],
      summaryByCurrency: {},
      totalByCurrency: [],
      datesSummaryList: [],
      methodByAmountTotal: 0,
      methodByCountTotal: 0,
      peiChartOption: {
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: p => `${p.name} : ${this.formatNum(p.value)} (${p.percent}%)`,
        },
        grid: {
          left: '10%',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          top: '5%',
          right: '5%',
          bottom: '5%',
        },
        series: [
          {
            name: 'Mehtod',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      trendReportChartOption: {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          type: 'scroll',
          selector: [
            {
              type: 'all or inverse',
              // 可以是任意你喜欢的 title
              title: 'Quick',
            },
          ],
        },
        grid: {
          left: '5%',
          top: '50px',
          right: '5%',
          show: false,
        },
        xAxis: {
          type: 'category',
          axisPointer: {
            type: 'shadow',
          },
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            name: 'Count',
            splitLine: { show: false },
            axisLabel: {
              formatter: function km(value) {
                if (value > 1000000) {
                  return `${value / 1000000}m`
                }
                if (value > 1000) {
                  return `${value / 1000}k`
                }
                return value
              },
            },
          },
          {
            type: 'value',
            name: 'Amount',
            splitLine: { show: false },
            axisLabel: {
              formatter: function km(value) {
                if (value > 1000000) {
                  return `${value / 1000000}m`
                }
                if (value > 1000) {
                  return `${value / 1000}k`
                }
                return value
              },
            },
          },
          {
            type: 'value',
            name: 'Rate',
            show: false,
          },
        ],
        series: [],
      },
      realIndex: 0,
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  watch: {
    data: {
      handler(newData) {
        if (!newData) {
          return
        }
        this.summaryByCurrency = newData
        const { currencies_summary } = this.summaryByCurrency
        this.calTotal(currencies_summary)
      },
      deep: true,
    },
  },
  methods: {
    calTotal(currenciesSummary) {
      let total = []
      Object.keys(currenciesSummary).forEach(item => {
        const curTemplate = { currency: item, income: currenciesSummary[item].total.Income, outlay: currenciesSummary[item].total.Outlay }
        total.push(curTemplate)
      })
      total = this.sortBykey(total, 'income')
      this.totalByCurrency = total
      if (total.length > 0) {
        setTimeout(() => {
          this.initGraph()
          this.swiperSlideClick(total[0].currency)
        }, 500)
      }
    },
    calDateSummary(datesSummary, methodByAmount, methodByCount) {
      let dates = []
      Object.keys(datesSummary).forEach(day => {
        dates.push(day)
      })
      dates = dates.sort()

      const categories = []
      const incomes = []
      const outlays = []
      const totalCnt = []
      const successCnt = []
      const refundCnt = []
      const chargebackCnt = []
      dates.forEach(day => {
        categories.push(day)
        incomes.push(datesSummary[day].Income)
        outlays.push(datesSummary[day].Outlay)
        totalCnt.push(datesSummary[day].total_cnt)
        successCnt.push(datesSummary[day].success_cnt)
        refundCnt.push(datesSummary[day].refund_cnt)
        chargebackCnt.push(datesSummary[day].chargeback_cnt)
      })
      const income = { name: 'Income', data: incomes }
      const outlay = { name: 'Outlay', data: outlays }
      this.datesSummaryList = []
      this.datesSummaryList.push(income)
      this.datesSummaryList.push(outlay)

      const trendReportChartOptionSeries = []
      const totalTrend = {
        name: 'Total',
        type: 'line',
        data: totalCnt,
      }
      trendReportChartOptionSeries.push(totalTrend)
      const successTrend = {
        name: 'Success',
        type: 'line',
        data: successCnt,
      }
      trendReportChartOptionSeries.push(successTrend)
      const refundTrend = {
        name: 'Refund',
        type: 'line',
        data: refundCnt,
      }
      trendReportChartOptionSeries.push(refundTrend)
      const chargebackTrend = {
        name: 'Chargeback',
        type: 'line',
        data: chargebackCnt,
      }
      trendReportChartOptionSeries.push(chargebackTrend)
      const incomeAmount = {
        name: 'Incomes',
        type: 'bar',
        yAxisIndex: 1,
        data: incomes,
      }
      trendReportChartOptionSeries.push(incomeAmount)
      const outlayAmount = {
        name: 'Outlays',
        type: 'bar',
        yAxisIndex: 1,
        data: outlays,
      }
      trendReportChartOptionSeries.push(outlayAmount)

      const methodByAmountListTemp = []
      const methodByCountListTemp = []
      this.methodByAmountTotal = 0
      this.methodByCountTotal = 0
      Object.keys(methodByAmount).forEach(method => {
        const template = { value: methodByAmount[method], name: method }
        methodByAmountListTemp.push(template)
        this.methodByAmountTotal += methodByAmount[method]
      })
      this.methodByAmountTotal = Math.round(this.methodByAmountTotal * 100) / 100
      Object.keys(methodByCount).forEach(method => {
        const template = { value: methodByCount[method], name: method }
        methodByCountListTemp.push(template)
        this.methodByCountTotal += methodByCount[method]
      })
      this.methodByCountTotal = Math.round(this.methodByCountTotal * 100) / 100

      setTimeout(() => {
        this.trendReportChartOption.xAxis.data = categories
        this.trendReportChartOption.series = trendReportChartOptionSeries

        this.$refs.trendReportChart.setOption(this.trendReportChartOption)

        // this.methodByAmountInfo[0].data = methodByAmountListTemp
        const methodByAmountChartOption = this.peiChartOption
        methodByAmountChartOption.series[0].data = methodByAmountListTemp
        this.$refs.methodByAmountChart.setOption(methodByAmountChartOption)

        // this.methodByCountInfo[0].data = methodByCountListTemp
        const methodByCountChartOption = this.peiChartOption
        methodByCountChartOption.series[0].data = methodByCountListTemp
        this.$refs.methodByCountChart.setOption(methodByCountChartOption)
      }, 200)
    },
    swiperSlideClick(currency) {
      const { currencies_summary } = this.summaryByCurrency
      const { dates, method_by_amount, method_by_count } = currencies_summary[currency]
      this.calDateSummary(dates, method_by_amount, method_by_count)
    },
    sortBykey(ary, key) {
      return ary.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        if (x < y) {
          return 1
        }
        if (x > y) {
          return -1
        }
        return 0
      })
    },
    initGraph() {
      this.trendReportChartOption.xAxis.data = []
      this.trendReportChartOption.series = []
      this.$refs.trendReportChart.setOption(this.trendReportChartOption)

      this.$refs.methodByAmountChart.setOption(this.peiChartOption)
      this.$refs.methodByCountChart.setOption(this.peiChartOption)
    },
    formatNum(strNum) {
      if (strNum.length <= 3) {
        return strNum
      }
      if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum
      }
      const a = RegExp.$1
      let b = RegExp.$2
      const c = RegExp.$3
      const re = new RegExp()
      re.compile('(\\d)(\\d{3})(,|$)')
      while (re.test(b)) {
        b = b.replace(re, '$1,$2$3')
      }
      return `${a}${b}${c}`
    },
  },
}
</script>

<style lang="scss">
.chart {
  height: 400px;
}
</style>
